
import { ref, defineComponent } from "vue";
import CloudFun from "@cloudfun/core";
import { useRouter } from 'vue-router';
import FilterBar from "@/components/filter-bar/Main.vue";
// import CfTabsBar from "@/components/tabs-bar/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import FileUpload, { VueUploadItem } from "vue-upload-component";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";


export default defineComponent({
  components: {
    // CfTabsBar,
    Popover,
    PopoverButton,
    PopoverPanel,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    FileUpload
  },
  props: {
    umousewheel: { type: Boolean, default: true },
    navigation: { type: Boolean, dafault: true },
    pagination: { type: Boolean, default: true },
    pageSize: { type: Number, default: 3 },
    itemSpace: { type: Number, default: 15 },
    itemWidth: { type: Number, default: 800 },
    itemHeight: { type: Number, default: 600 },
    uploaderWidth: { type: Number, default: 300 },
    uploaderHeight: { type: Number, default: 200 },
    maxImages: { type: Number, default: 5 },
    galleryId: String,
    lazy: { type: Boolean, dafault: true },
    width: String,
    title: { type: String, default: "相片" },
    filePostAction: String,
    modelValue: Array,
  },
  setup() {
    const uploadAction = `${process.env.VUE_APP_API}/api/files`;
    const uploader = ref<{
      previewSrc?: string;
      files: any[];
      upload: () => Promise<any[]>;
      removeAllFile: () => Promise<any[]>;
    }>({
      files: [],
      upload: () => Promise.resolve([]),
      removeAllFile: () => Promise.resolve([]),
    });
    const router = useRouter();
    const accept = (close) => {
      console.log('close');
      close();
    }
    const editingImage = ref({});
    const instance = ref<any>({})
    const value = ref<string | string[] | undefined>()
    const files = ref<VueUploadItem[]>([])
    return {
      tabs: [
        { name: "PROJECT", href: "/project", current: false },
        { name: "ALBUM", href: "/album", current: true },
        { name: "MODEL", href: "/model", current: false },
      ],
      toolbar: [
        {
          tooltipMsg: "Sharing group",
          icon: "icon-user",
        },
        {
          tooltipMsg: "Delete",
          icon: "icon-delete",
        },
        {
          tooltipMsg: "Rename",
          icon: "icon-edit",
        },
      ],
      instance,
      uploadAction,
      uploader,
      editingImage,
      files,
      accept,
      value
    };
  },
  methods: {

    /**
     * Has changed
     * @param  Object|undefined   newFile   只读
     * @param  Object|undefined   oldFile   只读
     * @return undefined
     */
    input(current: any, original: any) {
      const URL = window.URL || window.webkitURL;
      if (!URL || !URL.createObjectURL) return;
      this.$emit('input', current, original);
      // revoke object url
      // if (original?.file?.url && current?.file?.url !== original.file.url) URL.revokeObjectURL(original.file.url);
      // add file
      if (current && !original && current.fileObject) {
        let objectUrl = URL.createObjectURL(current.file)
        // if (current?.file?.type && typeof current.file.type.startsWith && current.file.type.startsWith('image/')) {
        const image = new Image()
        image.src = objectUrl
        current.file.url = objectUrl
        // this.$emit('load', current)
        // this.loading = true
        image.onload = () => {
          // this.previewSrc = this.defaultImage || `https://via.placeholder.com/${this.limitedWidth}x${this.limitedHeight}.png`

          setTimeout(() => {
            const file = this.files.find((e: any) => e.id === current.id)
            if (file) { file.active = true; this.upload(); }
          }, 500)

        }
        // image.onerror = (event) => {
        //   this.loading = false
        //   this.$emit('loadError', event)
        // }
        // }
      }
      // if (newFile && oldFile && !newFile.active && oldFile.active) {
      //   // 获得相应数据
      //   console.log('response', newFile.response)
      //   if (newFile.xhr) {
      //     //  获得响应状态码
      //     console.log('status', newFile.xhr.status)
      //   }
      // }

      // 自動上傳
      // if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
      //   if (!(this.$refs['upload'] as any).active) {
      //     (this.$refs['upload'] as any).active = true`
      //   }
      // }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   读写
     * @param  Object|undefined   oldFile   只读
     * @param  Function           prevent   阻止回调
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // 過濾不適圖片類型的檔案
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      // 创建 blob 字段 用于图片预览
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
    upload() {
      if (this.files.length) {
        const files = [...this.files]
        return Promise.all(files.filter((e) => e.active).map((file) => new Promise<VueUploadItem>((resolve, reject) => {
          this.instance.upload(file).then(
            (response: any) => {
              this.instance.update(file, { active: false, success: !file.error })
              response.active = false
              response.success = true
              console.log(this)
              // this.$emit('update:modelValue', this.value, this.id)
              this.$emit('uploaded', response)
              resolve(response)
            },
            (error: any) => {
              this.instance.update(file, {
                active: false,
                success: false,
                error: error.message || error.code || error.error || error
              })
              this.$emit('uploadError', file, error)
              reject(error)
            }
          )
        })))
      }
    },
  }
});
